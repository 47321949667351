<template>
  <v-select
    v-if="statusRequestTvs !== 'fetching'"
    :items="tvs"
    return-object
    v-model="selectedTv"
    :label="$t('remoteControl.selectTv')"
    single-line
    auto
    required
    autocomplete
    prepend-icon="mdi-television"
    item-text="name"
    :readonly="onlyOneTv"
  ></v-select>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  created() {
    this.initData();
  },
  computed: {
    ...mapState({
      authResult: ({ session }) => session.authResult,
      tvs: ({ remoteControl }) => remoteControl.tvs,
      statusRequestTvs: ({ remoteControl }) => remoteControl.statusRequestTvs,
      onlyOneTv: ({ remoteControl }) => remoteControl.onlyOneTv,
    }),
    selectedTv: {
      get() {
        return this.$store.state.remoteControl.selectedTv;
      },
      set(val) {
        this.setSelectedTv(val);
      },
    },
  },
  methods: {
    ...mapActions("remoteControl", ["getTvs", "sendCmd"]),
    ...mapMutations("remoteControl", {
      setRoomNr: "SET_ROOM_NR",
      setSelectedTv: "SET_SELECTED_TV",
      setSpecialDevicesCalled: "SET_SPECIAL_DEVICES_CALLED",
    }),
    initData() {
      this.setRoomNr(this.authResult.room);
      this.setSpecialDevicesCalled(this.$config.remove_special_devices_called);
      this.getTvs();
    },
  },
};
</script>
