<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card text>
        <v-card-text class="py-0">
          <remote-control-select></remote-control-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">
            {{ $t("modal.cancel") }}
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="!selectedTv.id"
            @click="
              playRadioChannel({
                room_ip_id: selectedTv.id,
                item_id: selectedId,
              }),
                (dialog = false)
            "
            >{{ $t("modal.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-list>
      <v-subheader list>
        {{ $t("webradio.countStations", { cnt: items.length }) }}
      </v-subheader>
      <template v-for="(station, index) in items">
        <v-divider v-if="index > 0" :key="`divider-${station.id}`"></v-divider>
        <v-lazy
          :options="{
            threshold: 0.5,
          }"
          min-height="56"
          :key="station.id"
        >
          <v-list-item
            @click="selectedStation = station"
            :key="station.id"
            data-v-step="radio_2"
          >
            <v-list-item-avatar tile>
              <v-img
                contain
                :src="$config.managerMedia + station.image"
                :lazy-src="$config.managerMedia + station.image"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ station.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action data-v-step="radio_3">
              <v-icon
                v-if="station.is_fav"
                @click.native.stop.capture.prevent
                @click.stop.capture.prevent="
                  removeFavorite({ stationID: station.id })
                "
                color="primary"
                >mdi-heart</v-icon
              >
              <v-icon
                v-else
                @click.native.stop.capture.prevent
                @click.stop.capture.prevent="
                  addFavorite({ stationID: station.id })
                "
                >mdi-heart-outline</v-icon
              >
            </v-list-item-action>
            <v-list-item-action v-if="!noTv" data-v-step="radio_4">
              <v-icon
                @click.native.stop.capture.prevent
                @click.stop.capture.prevent="
                  (selectedId = station.id), (dialog = !dialog)
                "
                >mdi-cast</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-lazy>
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import RemoteControlSelect from "@/components/remoteControl/RemoteControlSelect";

export default {
  data: () => ({
    dialog: false,
  }),
  components: {
    RemoteControlSelect,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disableFavorite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      selectedTv: (state) => state.remoteControl.selectedTv,
      noTv: ({ remoteControl }) => remoteControl.noTv,
    }),
    selectedStation: {
      get() {
        return this.playingStation;
      },
      set(val) {
        this.setPlayingStation(val);
      },
    },
  },
  methods: {
    ...mapActions("webradio", [
      "playRadioChannel",
      "addFavorite",
      "removeFavorite",
    ]),
    ...mapMutations("webradio", {
      setSelectedCountry: "SET_SELECTED_COUNTRY",
      setPlayingStation: "SET_PLAYING_STATION",
    }),
  },
};
</script>
