var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"text":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('remote-control-select')],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("modal.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.selectedTv.id},on:{"click":function($event){_vm.playRadioChannel({
              room_ip_id: _vm.selectedTv.id,
              item_id: _vm.selectedId,
            }),
              (_vm.dialog = false)}}},[_vm._v(_vm._s(_vm.$t("modal.ok")))])],1)],1)],1),_c('v-list',[_c('v-subheader',{attrs:{"list":""}},[_vm._v(" "+_vm._s(_vm.$t("webradio.countStations", { cnt: _vm.items.length }))+" ")]),_vm._l((_vm.items),function(station,index){return [(index > 0)?_c('v-divider',{key:`divider-${station.id}`}):_vm._e(),_c('v-lazy',{key:station.id,attrs:{"options":{
          threshold: 0.5,
        },"min-height":"56"}},[_c('v-list-item',{key:station.id,attrs:{"data-v-step":"radio_2"},on:{"click":function($event){_vm.selectedStation = station}}},[_c('v-list-item-avatar',{attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.$config.managerMedia + station.image,"lazy-src":_vm.$config.managerMedia + station.image}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(station.name))])],1),_c('v-list-item-action',{attrs:{"data-v-step":"radio_3"}},[(station.is_fav)?_c('v-icon',{attrs:{"color":"primary"},on:{"!click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeFavorite({ stationID: station.id })}},nativeOn:{"!click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v("mdi-heart")]):_c('v-icon',{on:{"!click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addFavorite({ stationID: station.id })}},nativeOn:{"!click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v("mdi-heart-outline")])],1),(!_vm.noTv)?_c('v-list-item-action',{attrs:{"data-v-step":"radio_4"}},[_c('v-icon',{on:{"!click":function($event){$event.stopPropagation();$event.preventDefault();(_vm.selectedId = station.id), (_vm.dialog = !_vm.dialog)}},nativeOn:{"!click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v("mdi-cast")])],1):_vm._e()],1)],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }