<template>
  <v-list three-line>
    <template v-for="(channel, index) in items">
      <v-divider v-if="index > 0" :key="`divider-${channel.id}`"></v-divider>

      <v-lazy
        :options="{
          threshold: 0.5,
        }"
        min-height="88"
        :key="channel.id"
      >
        <v-skeleton-loader
          :id="channel.id"
          v-intersect="init"
          :key="channel.id"
          :loading="channel.isFetchingCurrentEvent || !channel.status"
          type="list-item-avatar-three-line"
        >
          <v-list-item @click="playOrRedirect(channel)">
            <v-list-item-avatar tile class="align-self-center align-center">
              <v-img
                contain
                :src="$config.managerMedia + (channel.logo || channel.image)"
                :lazy-src="
                  $config.managerMedia + (channel.logo || channel.image)
                "
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ channel.name }}</v-list-item-title>
              <template
                v-if="channel.currentEvent && channel.currentEvent.event_name"
              >
                <v-list-item-subtitle>{{
                  channel.currentEvent.event_name
                }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-progress-linear
                    height="3"
                    v-if="
                      channel.currentEvent.percent_passed > 0 ||
                      channel.currentEvent.isFetchingCurrentEvent
                    "
                    :indeterminate="channel.isFetchingCurrentEvent"
                    :value="channel.currentEvent.percent_passed"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </template>
              <v-list-item-subtitle v-else>{{
                $t("epg.noInformation")
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                v-if="!noTv"
                @click.native.stop.capture.prevent
                @click.stop.capture.prevent="
                  (selectedChannel = channel), (dialog = !dialog)
                "
                data-v-step="epg_0"
                >mdi-cast</v-icon
              >
              <v-icon
                data-v-step="epg_1"
                @click.native.stop.capture.prevent
                :disabled="!channel.currentEvent"
                :to="{ name: 'epgDetail', params: { channelId: channel.id } }"
                >mdi-dots-horizontal</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-skeleton-loader>
      </v-lazy>
    </template>
    <template>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card text>
          <v-card-text class="py-0">
            <remote-control-select></remote-control-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dialog = false">{{
              $t("modal.cancel")
            }}</v-btn>
            <v-btn
              text
              color="primary"
              :disabled="!selectedTv.id"
              @click="
                $ga.event({
                  eventCategory: 'Epg Cast',
                  eventAction: 'channel',
                  eventLabel: `${selectedChannel.name}`,
                  eventValue: 1,
                });
                playEPGChannel({
                  room_ip_id: selectedTv.id,
                  start_channel_id: selectedChannel.id,
                });
                dialog = false;
              "
              >{{ $t("modal.ok") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-list>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import RemoteControlSelect from "@/components/remoteControl/RemoteControlSelect";

export default {
  data: () => ({
    dialog: false,
  }),
  components: {
    RemoteControlSelect,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  created() {
    this.setTimezone(this.$config.dateTimeZone);
  },
  computed: {
    ...mapState({
      selectedTv: ({ remoteControl }) => remoteControl.selectedTv,
      noTv: ({ remoteControl }) => remoteControl.noTv,
    }),
  },
  methods: {
    init(entries, observer) {
      this.$nextTick(() => {
        this.getEpgDataFromNow({ item_id: entries[0].target.id });
      });
    },
    ...mapMutations("epg", {
      setTimezone: "SET_TIMEZONE",
      resetEpgEventsData: "RESET_EPG_EVENTS_DATA",
    }),
    ...mapActions("epg", ["playEPGChannel", "getEpgDataFromNow"]),
    ...mapActions("appplayer", ["setVideoUrl", "setPlayState"]),
    playOrRedirect(channel) {
      if (this.$store.state.session.mode === "demo-use-pms") {
        this.setVideoUrl({ url: channel.stream });
        this.setPlayState({ state: "play" });
      } else if (channel.currentEvent) {
        this.$router.push({
          name: "epgDetail",
          params: { channelId: channel.id },
        });
      }
    },
  },
  beforeDestroy() {
    this.resetEpgEventsData();
  },
};
</script>
