var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.items),function(channel,index){return [(index > 0)?_c('v-divider',{key:`divider-${channel.id}`}):_vm._e(),_c('v-lazy',{key:channel.id,attrs:{"options":{
        threshold: 0.5,
      },"min-height":"88"}},[_c('v-skeleton-loader',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.init),expression:"init"}],key:channel.id,attrs:{"id":channel.id,"loading":channel.isFetchingCurrentEvent || !channel.status,"type":"list-item-avatar-three-line"}},[_c('v-list-item',{on:{"click":function($event){return _vm.playOrRedirect(channel)}}},[_c('v-list-item-avatar',{staticClass:"align-self-center align-center",attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.$config.managerMedia + (channel.logo || channel.image),"lazy-src":_vm.$config.managerMedia + (channel.logo || channel.image)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(channel.name))]),(channel.currentEvent && channel.currentEvent.event_name)?[_c('v-list-item-subtitle',[_vm._v(_vm._s(channel.currentEvent.event_name))]),_c('v-list-item-subtitle',[(
                    channel.currentEvent.percent_passed > 0 ||
                    channel.currentEvent.isFetchingCurrentEvent
                  )?_c('v-progress-linear',{attrs:{"height":"3","indeterminate":channel.isFetchingCurrentEvent,"value":channel.currentEvent.percent_passed}}):_vm._e()],1)]:_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("epg.noInformation")))])],2),_c('v-list-item-action',[(!_vm.noTv)?_c('v-icon',{attrs:{"data-v-step":"epg_0"},on:{"!click":function($event){$event.stopPropagation();$event.preventDefault();(_vm.selectedChannel = channel), (_vm.dialog = !_vm.dialog)}},nativeOn:{"!click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v("mdi-cast")]):_vm._e(),_c('v-icon',{attrs:{"data-v-step":"epg_1","disabled":!channel.currentEvent,"to":{ name: 'epgDetail', params: { channelId: channel.id } }},nativeOn:{"!click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v("mdi-dots-horizontal")])],1)],1)],1)],1)]}),[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"text":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('remote-control-select')],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("modal.cancel")))]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.selectedTv.id},on:{"click":function($event){_vm.$ga.event({
                eventCategory: 'Epg Cast',
                eventAction: 'channel',
                eventLabel: `${_vm.selectedChannel.name}`,
                eventValue: 1,
              });
              _vm.playEPGChannel({
                room_ip_id: _vm.selectedTv.id,
                start_channel_id: _vm.selectedChannel.id,
              });
              _vm.dialog = false;}}},[_vm._v(_vm._s(_vm.$t("modal.ok")))])],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }