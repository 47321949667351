<template>
  <v-list>
    <v-list-item
      v-for="item in items"
      :to="{ name: 'contentpage', params: { cpId: item.window_id } }"
      :key="item.id"
    >
      <v-list-item-avatar tile>
        <v-icon>mdi-library-books</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
